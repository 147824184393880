// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin sm-min-max {
    @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin sm-max {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin md-min-max {
    @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin md-max {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin lg-min-max {
    @media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
        @content;
    }
}

@mixin lg-max {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

// XXL devices
@mixin xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}

// Chevron button
@mixin chevron($margin-right: 0) {
    display: inline-block;
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    border: 1px solid $border-primary;
    border-radius: 50%;
    background-image: url("../public/img/lead/icon-chevron.svg");
    background-repeat: no-repeat;
    background-size: 12px 7px;
    background-position: 50%;
    margin-right: $margin-right;
}

//Navbar
@mixin nav($background: $color-white, $border-bottom: 0px) {
    min-height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1030;
    background: $background;
    border-bottom: $border-bottom;
}
@mixin navBtn {
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    text-align: -webkit-match-parent;
    white-space: nowrap;
    display: inline-block;
}
@mixin activeNavBtn {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    background-color: transparent;
}
@mixin progress-bar-section-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 6px;
    width: 6px;
    background: $color-white;
    border-radius: 6px;
    transform: translate(-50%, -50%);
}

//homepage section container
@mixin container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @include xl {
        max-width: 1140px;
    }

    @include lg-min-max {
        max-width: 960px;
    }

    @include md-min-max {
        max-width: 720px;
    }

    @include sm-min-max {
        max-width: 540px;
    }
}

//statement-from-homeowner
@mixin statement-from-homeowner {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $border-primary;
    text-align: center;
    display: block;

    @include lg {
        padding-top: 7.5rem;
        padding-bottom: 7.5rem;
    }
}
@mixin carousel-nav-btn {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    border: 1px solid $color-button-border;
    background: $color-white;
    color: $color-navigation-button;
    position: absolute;
    z-index: 100;
    text-transform: none;
    overflow: visible;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    top: 50%;
}
@mixin carousel-dot {
    width: 10px;
    height: 10px;
    /* background-color: rgb(117, 117, 117); */
    background: $color-navigation-button;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    cursor: pointer;
    /* border: 0.0625rem solid rgb(117, 117, 117); */
    color: $color-blue;
    padding: 0px;
    outline: 0px;
}

//start-earning
@mixin start-earning {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $border-primary;
    text-align: center;
    display: block;
    scroll-margin-top: 72px; //this value is equals to the height of navbar

    @include lg {
        padding-top: 7.5rem;
        padding-bottom: 7.5rem;
    }
}

//whyus
@mixin whyUs {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $border-primary;
    text-align: center;
    display: block;
    scroll-margin-top: 72px; //this value is equals to the height of navbar

    @include lg {
        padding-top: 7.5rem;
        padding-bottom: 7.5rem;
    }
}

//contactUs
@mixin contactUsSection {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $border-primary;
    text-align: center;
    scroll-margin-top: 72px; //this value is equals to the height of navbar

    // 992px window width and more
    @include lg {
        padding-top: 7.5rem;
        padding-bottom: 7.5rem;
    }
}
@mixin contactA($text-color) {
    color: $text-color;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
    text-align: left;
    display: block;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $text-color;
    }

    // 768px window width and more
    @include md {
        font-size: 1.375rem;
    }
}
@mixin contactAvail {
    font-size: 0.875rem;
    font-weight: 400;

    .greenAvailability {
        background: $color-button;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 0.5rem !important;
    }
    .redAvailability {
        background: $color-link;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 0.5rem !important;
    }
}

//lead register
@mixin form-leads-register($background-color) {
    margin-top: 0.25rem;
    margin-bottom: 1.5rem;

    @include lg {
        margin-top: 4rem;
        padding: 1.5rem;
        background: $background-color;
        border-radius: 4px;
        margin-bottom: 2.5rem;
    }

    @include md-min-max {
        margin-top: 2.5rem;
        padding: 1.5rem;
        background: $background-color;
        border-radius: 4px;
        margin-bottom: 2.5rem;
    }
}
@mixin form-header($text-color) {
    text-shadow: 2.7px 0 5px $color-text-shadow;
    font-size: 1.125rem;
    color: $color-white;
    line-height: 1.2;

    @include md {
        text-shadow: none;
        color: $text-color;
        font-size: 1.335rem;
        font-weight: 600;
    }
}
@mixin form-badge-step {
    display: inline-block;
    padding: 0.25rem 0.75rem 0.2rem;
    flex-shrink: 0;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 1.55rem;
    background: $color-white;
    margin-left: 0.5rem;
}

//form
@mixin form-control($border-color) {
    display: block;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-family: $button-font-family;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: $form-text-color;
    background-color: $color-white;
    background-clip: padding-box;
    border: 1px solid $border-color;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@mixin form-control-phone($border-color) {
    display: block;
    width: 100% !important;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 3.5rem !important;
    font-family: $button-font-family;
    font-size: 1rem !important;
    font-weight: 300;
    line-height: 1.5;
    color: $form-text-color;
    background-color: $color-white !important;
    background-clip: padding-box !important;
    border: 1px solid $border-color !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}
@mixin form-select {
    padding: 0.625rem 0.75rem;
    background: $color-white url(../public/icons/icon-chevron-down.svg) right 10px center no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
@mixin form-container($border-color) {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 2.5rem;
    // display: none;

    @include xl {
        margin-bottom: 3.5rem;
        margin-top: 3.5rem;
        display: block;
    }

    @include md {
        margin-left: 0;
        margin-right: 0;
        border: 1px solid $border-color;
        border-radius: 0.25rem;
    }
}
@mixin new-flow-form-header($border-color) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 15px;

    @include md {
        background: inherit;
        padding: 2rem 3rem;
        border-bottom: 1px solid $border-color;
    }

    h2 {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
        margin-top: 0;
        font-weight: 600;
        line-height: 1.2;
    }

    .icon-lightbulb {
        display: inline-block;
        width: 40px;
        height: 44px;
        flex-shrink: 0;
        // background-image: url(/icons/icon-lightbulb.svg);
        margin-left: 0.5rem;

        @include md {
            width: 50px;
            height: 54px;
        }
    }
}

//form-1
@mixin select-group-item {
    position: relative;
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;
    padding: 1.25rem 1.25rem 1.25rem 3.75rem;
    border-radius: 0.25rem;
    user-select: none;
    cursor: pointer;
    font-weight: 600;
}

//for form1 and form8
@mixin qualification-complete-container {
    text-align: center;
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    @include xl {
        margin-bottom: 3.5rem;
        margin-top: 3.5rem;
    }

    .icon-house {
        margin-bottom: 1.5rem;
    }

    .thank-you-header {
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .margin-between-text-and-btn {
        margin-bottom: 2rem;
    }
}

//form4
@mixin input-for-number-of-rooms {
    display: inline-block;
    padding: 3px 4px;
    width: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    border: 1px solid transparent;
    text-align: center;
    opacity: 1;
}

// Range slider
@mixin range-slider-container {
    display: flex;
    align-items: center;
    justify-content: center;

    input[type="range"] {
        height: 34px;
        -webkit-appearance: none;
        margin: 0px 20px;
        width: 100%;
    }
    input[type="range"]:focus {
        outline: none;
    }
    input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 11px;
        cursor: pointer;
        box-shadow: 1px 1px 1px $color-black;
        background: linear-gradient(
            to right,
            $color-range-slider-start 0%,
            $color-range-slider-middle 50%,
            $color-range-slider-end 100%
        );
        border-radius: 1px;
        border: 0px solid $color-range-slider-track-border;
    }
    input[type="range"]::-webkit-slider-thumb {
        box-shadow: 1px 1px 1px $color-black;
        border: 1px solid $color-range-slider-thumb-border;
        height: 26px;
        width: 26px;
        border-radius: 15px;
        background: $color-white;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -8px;
    }
    input[type="range"]:focus::-webkit-slider-runnable-track {
        background: linear-gradient(
            to right,
            $color-range-slider-start 0%,
            $color-range-slider-middle 50%,
            $color-range-slider-end 100%
        );
    }
    input[type="range"]::-moz-range-track {
        width: 100%;
        height: 11px;
        cursor: pointer;
        box-shadow: 1px 1px 1px $color-black;
        background: linear-gradient(
            to right,
            $color-range-slider-start 0%,
            $color-range-slider-middle 50%,
            $color-range-slider-end 100%
        );
        border-radius: 1px;
        border: 0px solid $color-range-slider-track-border;
    }
    input[type="range"]::-moz-range-thumb {
        box-shadow: 1px 1px 1px $color-range-slider-thumb-box-shadow;
        border: 1px solid $color-range-slider-thumb-border;
        height: 26px;
        width: 26px;
        border-radius: 15px;
        background: $color-white;
        cursor: pointer;
    }
    input[type="range"]::-ms-track {
        width: 100%;
        height: 11px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    input[type="range"]::-ms-fill-lower {
        background: linear-gradient(
            to right,
            $color-range-slider-start 0%,
            $color-range-slider-middle 50%,
            $color-range-slider-end 100%
        );
        border: 0px solid $color-range-slider-track-border;
        border-radius: 2px;
        box-shadow: 1px 1px 1px $color-black;
    }
    input[type="range"]::-ms-fill-upper {
        background: linear-gradient(
            to right,
            $color-range-slider-start 0%,
            $color-range-slider-middle 50%,
            $color-range-slider-end 100%
        );
        border: 0px solid $color-range-slider-track-border;
        border-radius: 2px;
        box-shadow: 1px 1px 1px $color-black;
    }
    input[type="range"]::-ms-thumb {
        margin-top: 1px;
        box-shadow: 1px 1px 1px $color-range-slider-thumb-box-shadow;
        border: 1px solid $color-range-slider-thumb-border;
        height: 26px;
        width: 26px;
        border-radius: 15px;
        background: $color-white;
        cursor: pointer;
    }
    input[type="range"]:focus::-ms-fill-lower {
        background: linear-gradient(
            to right,
            $color-range-slider-start 0%,
            $color-range-slider-middle 50%,
            $color-range-slider-end 100%
        );
    }
    input[type="range"]:focus::-ms-fill-upper {
        background: linear-gradient(
            to right,
            $color-range-slider-start 0%,
            $color-range-slider-middle 50%,
            $color-range-slider-end 100%
        );
    }
}

//radio-btn
@mixin radio-btn-container {
    position: relative;
    padding-left: 2rem;
    margin-right: 20px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default radio button */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* When the radio button is checked, add a green background */
    input:checked ~ .checkmark {
        background-color: $color-button;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }
}
/* Create a custom radio button */
@mixin checkmark {
    position: absolute;
    margin: 0 16px 0 0;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    border: 1px solid $color-button-border;
    -webkit-border: 1px solid $color-button-border;

    /* Create the indicator (the dot/circle - hidden when not checked) */
    &::after {
        content: "";
        position: absolute;
        display: none;

        /* Style the indicator (dot/circle) */
        top: 6px;
        left: 6px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: $color-white;
    }
}

//custom switch
@mixin switch {
    position: relative;
    display: flex;

    .switch-input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-white;
        border: 1px solid $color-slider-border;
        -webkit-border: 1px solid $color-slider-border;
        -webkit-transition: 0.4s;
        transition: 0.4s;

        &::before {
            position: absolute;
            content: "";
            height: 18px;
            width: 18px;
            left: 2px;
            bottom: 2px;
            background-color: $color-slider-border;
            -webkit-transition: 0.2s;
            transition: 0.2s;
        }
    }

    /* Rounded sliders */
    .slider-round {
        border-radius: 34px;
        width: 2.625rem;
        height: 1.5rem;

        &::before {
            border-radius: 50%;
        }
    }

    .switch-input:checked + .slider {
        background-color: $color-button;
    }

    .switch-input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
        background-color: $color-white;
    }
}

//ads footer
@mixin ads-footer-address {
    text-align: center;
    margin-bottom: 25px;
    width: 100%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;

    @include xl {
        text-align: left;
        width: 25%;
        float: left;
    }
    @include lg-min-max {
        text-align: left;
        width: 25%;
        float: left;
    }
    @include md-min-max {
        width: 50%;
        float: left;
        text-align: left;
    }
}
