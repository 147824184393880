@import "variables";
@import "mixins";

//Navbar
.nav-stugsommar {
    @include nav($color-white, 6px solid $se-blue-color);
}

.nav {
    @include nav($color-white, 0px);
}

.nav-dk {
    @include nav($color-white, 6px solid $color-yellow);
}

.nav-ads {
    @include nav($ads-section-color, 0px);
}

.nav-ads-border-bottom {
    border-bottom: 6px solid $ads-border;
}

.navBtn {
    color: $contact-reference;
    @include navBtn;
}
.navBtn-ads {
    color: $ads-font;
    @include navBtn;
    font-weight: 400;
}

//Navbar functionality
.link-logo {
    text-decoration: none;
    background-color: transparent;
    border: none;
}

.activeNavBtn {
    color: $color-link;
    @include activeNavBtn;
}

.activeNavBtn-ads {
    color: $ads-red-color;
    @include activeNavBtn;
    font-weight: 500;
}

.navBtnContainer {
    margin-left: auto;
    display: none;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    margin-top: 0;

    @include lg {
        display: flex;
    }
}

.btn {
    margin-left: 1rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 1.55rem;
    background: $color-button;
    border-color: $color-button;
    color: $color-white;
    display: inline-block;
    font-family: $button-font-family;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;

    &:hover {
        background: $color-button-hovor;
        border-color: $color-button-hovor;
        color: $color-white;
        text-decoration: none;
    }
}

.btnDisplayNone {
    margin-left: 1rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 1.55rem;
    background: $color-button;
    border-color: $color-button;
    color: $color-white;
    display: none;
    font-family: $button-font-family;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;
}

//Style for progress bar
.progress-bar {
    position: sticky;
    top: 72px;
    left: 0px;
    padding: 0px;
    width: 100vw;
    background: $color-white;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    z-index: 1019;
}

.progress-bar-section-selected {
    position: relative;
    margin-right: -2px;
    width: 17%;
    height: 10px;
    background: $color-button;
    clip-path: polygon(calc(100% - 6px) 0px, 100% 50%, calc(100% - 6px) 100%, 0% 100%, 6px 50%, 0% 0%);

    &:first-of-type {
        clip-path: polygon(0% 0%, calc(100% - 6px) 0px, 100% 50%, calc(100% - 6px) 100%, 0% 100%);
    }

    &:last-of-type {
        clip-path: polygon(100% 0px, 100% 50%, 100% 100%, 0% 100%, 6px 50%, 0% 0%);
        flex: 1 1 0%;
    }
}

.progress-bar-section {
    cursor: pointer;
    position: relative;
    margin-right: -2px;
    width: 17%;
    height: 10px;
    background: #cdd1d7;
    clip-path: polygon(calc(100% - 6px) 0px, 100% 50%, calc(100% - 6px) 100%, 0% 100%, 6px 50%, 0% 0%);

    &:first-of-type {
        clip-path: polygon(0% 0%, calc(100% - 6px) 0px, 100% 50%, calc(100% - 6px) 100%, 0% 100%);
    }

    &:last-of-type {
        clip-path: polygon(100% 0px, 100% 50%, 100% 100%, 0% 100%, 6px 50%, 0% 0%);
        flex: 1 1 0%;
    }
}

.progress-bar-section-dot-selected {
    display: none;
    @include progress-bar-section-dot;
}

.progress-bar-section-dot {
    @include progress-bar-section-dot;
}

//footer

.footerCopyright {
    align-items: center;
    background: $contact-reference;
    padding-top: 1.25rem;
    padding-bottom: 3.5rem;
    color: $paragraph-primary;
    border-top: 1px solid $paragraph-primary;
    font-size: 0.875rem;
    font-weight: 400;
    display: block;
}

/* @media (min-width: 576px){ */
.footerDivText {
    text-align: center;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 540px;
}
/* } */

/* Principal Footer */

.footerLinks {
    font-weight: 300;
    line-height: 1.5;
    text-align: left;

    // 768px window width and more
    @include md {
        padding-bottom: 2.5rem;
        padding-top: 3.5rem;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    // 576px window width and more
    @include sm {
        max-width: 540px;
    }
    // 768px window width and more
    @include md {
        max-width: 720px;
    }
    // 992px window width and more
    @include lg {
        max-width: 960px;
    }
    // 1200px window width and more
    @include xl {
        max-width: 1140px;
    }
}

.footerRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.footerColumnFrame {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    // 768px window width and more
    @include md {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    // 992px window width and more
    @include lg {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.referencesFrame {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    margin-right: -15px;
    border-color: $border-secondary;
    position: relative;
    margin-top: -1px;
    border-top: 1px solid;

    // 768px window width and more
    @include md {
        border-color: transparent;
    }
}

.footerColumnHeading {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $heading-alt;
    font-weight: 600;
    font-size: 1.125rem;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }
}

.footerMainLinksCollapsed {
    padding-left: 0;
    display: none;

    // 768px window width and more
    @include md {
        display: block;
    }
}

.footerMainLinks {
    padding-left: 0;
    display: block;
}

.iconChevronCollapsed {
    @include chevron(0);
    margin-left: 0.5rem;
    border-color: $border-secondary;

    // 768px window width and more
    @include md {
        display: none;
    }
}

.iconChevron {
    @include chevron(0);
    margin-left: 0.5rem;
    border-color: #656b77;
    transform: rotate(-180deg);

    // 768px window width and more
    @include md {
        display: none;
    }
}

.footerUlStyle {
    margin-bottom: 1.5rem;
    padding-left: 0;
    list-style: none;
}

.footerListItems {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    // 768px window width and more
    @include md {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.footerA {
    color: $paragraph-primary;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        color: $paragraph-primary;
        text-decoration: underline;
    }
}

.footerButtonCont {
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    width: 100%;

    // 768px window width and more
    @include md {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    // 992px window width and more
    @include lg {
        margin-left: 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.languageSelect {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.125rem;
    font-weight: 600;
    color: $heading-alt;
    display: block;
}

.buttonControl {
    margin-bottom: 1.5rem;
    word-wrap: normal;
    transform: none;
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-family: $button-font-family;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: $form-text-color;
    background-color: $color-white;
    background-clip: padding-box;
    border: 1px solid $color-button-border;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//ADS footer top

.footer-top {
    background: $ads-section-color-alt;
}

.ads-footer-container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;

    &::before {
        content: " ";
        display: table;
    }
    &::after {
        clear: both;
        content: " ";
        display: table;
    }

    @include xl {
        max-width: 1180px;
    }

    @include lg-min-max {
        max-width: 970px;
    }

    @include md-min-max {
        max-width: 750px;
    }

    @include sm-min-max {
        max-width: 540px;
    }
}

.ads-row {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;

    &::before {
        content: " ";
        display: table;
    }
    &::after {
        clear: both;
        content: " ";
        display: table;
    }
}

.ads-footer-title {
    text-align: center;
    margin-bottom: 15px;
    width: 100%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;

    @include xl {
        margin-left: 25%;
        width: 50%;
        float: left;
    }
    @include lg-min-max {
        margin-left: 25%;
        width: 50%;
        float: left;
    }
}

.ads-footer-title-text {
    // font-size: 22px;
    display: block;
    margin-bottom: 15px;

    font-size: 1.75rem;
    // margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;

    // 992px window width and more
    @include lg {
        font-size: 2.375rem;
    }
}

.ads-footer-address {
    @include ads-footer-address;
}
.ads-footer-address-hidden {
    @include ads-footer-address;
    @include sm-max {
        display: none !important;
    }
}

.ads-footer-contact {
    color: $ads-font;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
        color: $color-black;
    }
}
