@import "variables";
@import "mixins";

$partner-logo-box-shadow: rgb(0 0 0 / 10%);

.partners {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid $border-primary;
    text-align: center;
    display: block;

    @include lg {
        padding-top: 7.5rem;
        padding-bottom: 15rem;
    }
}

.partners-container {
    display: block;
    text-align: center;
    @include container;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    text-align: center;

    @include lg {
        align-items: center;
    }
}

.col-1 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;

    @include xl {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    @include lg-min-max {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
}

.partners-header {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    text-align: center;

    @include lg {
        font-size: 3.375rem;
    }
}

.partners-header-text {
    font-size: 1rem;
    font-weight: 400;
    color: $color-text;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;

    @include lg {
        font-size: 1.25rem;
    }
}
.partners-header-text-ads {
    font-size: 1rem;
    font-weight: 400;
    color: $ads-font;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;

    @include lg {
        font-size: 1.25rem;
    }
}

.partners-logo-container {
    text-align: center;

    @include lg {
        display: none;
    }
}

.partner-logo-row-mb {
    margin-bottom: 1rem;
    align-items: center;
    justify-content: space-between;
    display: flex;
    text-align: center;
}

.partner-logo-row {
    align-items: center;
    justify-content: space-between;
    display: flex;
    text-align: center;
}

.partner-logo {
    display: inline-block;
    width: 60px;
    height: 60px;
    background: $color-white;
    box-shadow: 0 2px 22px $partner-logo-box-shadow;
    border-radius: 10px;
    text-align: center;

    @include md {
        width: 100px;
        height: 100px;
    }
}

.btn {
    margin-top: 1.5rem;
    background: $color-button;
    border-color: $color-button;
    color: $color-white;
    display: inline-block;
    font-family: $button-font-family;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.55rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;

    &:hover {
        background: $color-button-hovor;
        border-color: $color-button-hovor;
        color: $color-white;
        text-decoration: none;
    }
}

.col-2 {
    display: none;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    text-align: center;

    @include xl {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        display: block;
    }

    @include lg-min-max {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
        display: block;
    }
}

.image {
    margin-left: 2rem;
    vertical-align: middle;
    border-style: none;
}
