// Theme Colors
$section-color-alt: #f7f7f8;
$border-primary: #e0e1e3;
$color-text: #a2a6ad;
$color-white: #fff;
$color-link: #ed1c24;
$color-button: #4bbb4b;
$color-button-hovor: #208920;
$color-button-border: #c1c3c6;
$border-secondary: #656b77;
$paragraph-primary: #a2a6ad;
$contact-reference: #3c4047;
$heading-alt: #eff0f1;
$color-yellow: #ffd100; //For step number #f7ab00
$color-yellow-button-hover: #e8b908;
$form-text-color: #495057;
$color-black: #000000;

//stugsommar colors
$color-blue: #01529e;
$se-blue-color: #00529e;

// ads colors
$ads-section-color-alt: #efdcbd;
$ads-section-color: #fff4e0;
$ads-font: #003263;
$ads-border: #62909d;
$ads-red-color: #94102e;
$ads-btn-hover-color: #e4caa1;

//Font
$button-font-family: proxima-nova, Arial, sans-serif;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
$screen-sm-max: 767px;

// Small tablets (portrait view)
$screen-md-min: 768px;
$screen-md-max: 991px;

// Tablets and small desktops
$screen-lg-min: 992px;
$screen-lg-max: 1199px;

// Large tablets and desktops
$screen-xl-min: 1200px;

//Extra Large
$screen-xxl-min: 1340px;
