@import "variables";
@import "mixins";

$color-navigation-button: #333;
$color-blue: rgb(100, 182, 231);

.homeowner-statement-and-partner-section {
    scroll-margin-top: 72px; //this value is equals to the height of navbar
}
.statement-from-homeowner {
    background: $section-color-alt;
    @include statement-from-homeowner;
}
.statement-from-homeowner-ads {
    background: $ads-section-color-alt;
    @include statement-from-homeowner;
}

.statement-from-homeowner-container {
    display: block;
    text-align: center;
    @include container;
}

.statement-from-homeowner-header {
    font-size: 1.75rem;
    margin-bottom: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    text-align: center;

    @include lg {
        font-size: 3.375rem;
    }
}

.testimonial-container {
    margin-top: 3rem;
    margin-bottom: 5rem;
    display: block;
    text-align: center;

    @include lg {
        margin-top: 6rem;
    }
}

.carousel-cell {
    width: 100%;
    margin-right: 10px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    @include md {
        align-items: center;
    }
}

.col-1 {
    margin-bottom: 0.5rem;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @include md {
        order: 1;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.testimonial-img {
    position: relative;
    height: 340px;
    // width: 250px;
    border-radius: 0.25rem;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 3.5rem;
    margin-bottom: 2.5rem;

    @include md {
        height: 562px;
        // width: auto;
        margin-bottom: 0;
    }
}

.col-2 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @include lg {
        margin-left: 8.33333%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        order: 2;
    }

    @include md-min-max {
        flex: 0 0 50%;
        max-width: 50%;
        order: 2;
    }
}

.image {
    margin-bottom: 1rem;
    vertical-align: middle;
    border-style: none;
}

.testimonial-quote {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;

    @include lg {
        font-size: 2rem;
    }
}

.testimonial-homeowner {
    color: $color-link;
    font-weight: 700;
}

.previous-button {
    @include carousel-nav-btn;
    left: -10px;

    @include xxl {
        left: -100px;
        width: 44px;
        height: 44px;
    }
}

.next-button {
    @include carousel-nav-btn;
    right: -10px;

    @include xxl {
        right: -100px;
        width: 44px;
        height: 44px;
    }
}

.flickity-button-icon {
    position: absolute;
    /* fill: #656b77!important; */
    left: 30%;
    top: 30%;
    width: 40%;
    height: 40%;
    overflow: hidden;
    vertical-align: middle;
    fill: currentColor;
}

.dot-container {
    text-align: center;
    margin-top: 2rem;
}

.dots {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px 0.5rem;
}

.dot {
    @include carousel-dot;
    opacity: 0.3;
}

.dot-selected {
    @include carousel-dot;
}
