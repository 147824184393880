@import "variables";
@import "mixins";

.whyUs {
    background: $section-color-alt;
    @include whyUs;
}
.whyUs-ads {
    background: $ads-section-color-alt;
    color: $ads-font;
    @include whyUs;
}

.whyUs-container {
    display: block;
    text-align: center;
    @include container;
}

.whyUs-header {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    text-align: center;

    @include lg {
        font-size: 3.375rem;
    }
}

.whyUs-header-text {
    font-size: 1rem;
    font-weight: 400;
    color: $color-text;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;

    @include lg {
        font-size: 1.25rem;
    }
}
.whyUs-header-text-ads {
    font-size: 1rem;
    font-weight: 400;
    color: $ads-font;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;

    @include lg {
        font-size: 1.25rem;
    }
}

.renting-usps {
    margin: 2rem -15px 6rem;
    display: block;
    text-align: center;

    @include md {
        margin-top: 3.5rem;
        margin-bottom: 0;
    }
}

.renting-usps-container {
    text-align: center;

    @include md {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }
}

.cell {
    display: block;
    text-align: center;

    @include lg {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        margin-left: 0;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    @include md-min-max {
        flex: 0 0 50%;
        max-width: 50%;
        margin-left: 0;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (max-width: 576px) {
        width: 80%;
        margin-left: 10%;
        display: block;
        text-align: center;
    }
}

.usp {
    padding: 2rem;
    border-radius: 4px;
    border: 1px solid $border-primary;
    background: $color-white;
    min-height: 320px;
    display: block;
    text-align: center;

    @include md {
        margin-bottom: 4.75rem;
        padding: 0;
        border-radius: 0;
        border: none;
        background: transparent;
        min-height: auto;
    }
}

.image {
    margin-bottom: 1.5rem;
    vertical-align: middle;
    border-style: none;
    text-align: center;
}

.usp-header {
    font-size: 1.125rem;
    margin-bottom: 0.25rem;
    display: block;
    font-weight: 600;
    text-align: center;
}

.usp-text {
    color: $color-text;
    display: block;
    text-align: center;

    a {
        color: $color-link;
        text-decoration: none;
        background-color: transparent;
        text-align: center;

        &:hover {
            text-decoration: underline;
        }
    }
}
.usp-text-ads {
    color: $ads-font;
    display: block;
    text-align: center;

    a {
        color: $color-link;
        text-decoration: none;
        background-color: transparent;
        text-align: center;

        &:hover {
            text-decoration: underline;
        }
    }
}

.link {
    color: $color-link;
    text-decoration: none;
    background-color: transparent;
    text-align: center;
}

.link:hover {
    text-decoration: underline;
}

.btn {
    margin-top: 1.5rem;
    background: $color-button;
    border-color: $color-button;
    color: $color-white;
    display: inline-block;
    font-family: $button-font-family;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.55rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;

    &:hover {
        background: $color-button-hovor;
        border-color: $color-button-hovor;
        color: $color-white;
        text-decoration: none;
    }
}
