@import "variables";
@import "mixins";

.start-earning {
    @include start-earning;
}
.start-earning-ads {
    background-color: $ads-section-color;
    color: $ads-font;
    @include start-earning;
}

.start-earning-container {
    display: block;
    text-align: center;
    @include container;
}

.start-earning-header {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    text-align: center;

    @include lg {
        font-size: 3.375rem;
    }
}

.step-container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    text-align: center;

    @include lg {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
}

.col-content {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: block;
    text-align: center;

    @include lg {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.icon-container {
    position: relative;
    margin-bottom: 2rem;
    display: block;
    text-align: center;
}

.circle {
    position: absolute;
    height: 54px;
    width: 54px;
    top: 17px;
    left: -41px;
    border: 6px solid $color-white;
    line-height: 44px;
    background: $color-link;
    display: inline-block;
    border-radius: 50%;
    font-weight: 600;
    font-size: 1.375rem;
    text-align: center;
    color: $color-white;
    z-index: 1;
}

.image {
    vertical-align: middle;
    border-style: none;
    text-align: center;
    z-index: -1;
}

.step-header {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
    display: block;
    font-weight: 600;
    text-align: center;
}

.step-text {
    color: $color-text;
    width: 75%;
    display: block;
    text-align: center;
}
.step-text-ads {
    color: $ads-font;
    width: 75%;
    display: block;
    text-align: center;
}

.col-arrow {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: block;
    text-align: center;
}

.step-divider {
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
    display: block;
    text-align: center;
}

.image-icon-arrow {
    vertical-align: middle;
    border-style: none;
    text-align: center;

    @include lg {
        transform: rotate(-90deg);
    }
}

.btn {
    background: $color-button;
    border-color: $color-button;
    color: $color-white;
    display: inline-block;
    font-family: $button-font-family;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.55rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-decoration: none;

    &:hover {
        background: $color-button-hovor;
        border-color: $color-button-hovor;
        color: $color-white;
        text-decoration: none;
    }
}
