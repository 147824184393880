@import "variables";
@import "mixins";

$color-text-shadow: #333;
$color-tooltip-border: #fff7d2;
$color-tooltip-inner: #ae9354;
$color-slider-border: #adb5bd; //for switch's background also.
$color-loader-border: #f3f3f3;

// registration form style
.leadRegister {
    position: relative;
    min-height: 480px;
    background-size: cover;
    z-index: unset;
    // background-image: url("../public/img/lead/background-wwwdk.jpg");
    scroll-margin-top: 72px; //this value is equals to the height of navbar

    @include lg {
        min-height: 580px;
    }
}

.page-hero-content {
    flex-direction: column;
}

.page-hero-content-overlay {
    flex-direction: column;
    top: 0%;
    left: 0%;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1041;
}

.lead-register-container {
    @include container;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-1 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @include md {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.lead-register-header {
    text-shadow: 2.7px 0 5px $color-text-shadow;
    margin-top: 2.5rem;
    font-size: 2.375rem;
    color: $color-white;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 0.5rem;

    @include xl {
        margin-top: 7rem;
        font-size: 4rem;
    }

    @include lg-min-max {
        margin-top: 7rem;
    }

    @include md-min-max {
        margin-top: 10rem;
    }
}

.col-2 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    @include lg {
        margin-left: 8.33333%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    @include md-min-max {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.form-leads-register {
    @include form-leads-register($color-white);
}
.form-leads-register-ads {
    @include form-leads-register($ads-section-color-alt);
}

.leads-form-step {
    display: block;
}

.form-title {
    margin-bottom: 1rem;
    align-items: baseline;
    justify-content: space-between;
    display: flex;
}

.form-header {
    @include form-header($contact-reference);
}
.form-header-ads {
    @include form-header($ads-font);
}

.form-badge-step {
    color: $color-yellow;
    border: 1px solid $color-yellow;
    @include form-badge-step;
}
.form-badge-step-ads {
    color: $ads-font;
    border: 1px solid $ads-font;
    @include form-badge-step;
}
.form-badge-step-se {
    color: #00529e;
    border: 1px solid #00529e;
    @include form-badge-step;
}

.form-group {
    margin-bottom: 1rem;
}

.pac-target-input:not(:-webkit-autofill) {
    animation-name: endBrowserAutofill;
}

.form-control {
    width: 100%;
    @include form-control($color-button-border);

    // To give style to the phonenumber input
    input {
        border: none;
        font-size: 1rem;
        opacity: 0.8;
        margin-left: 5px;
    }
}
.form-control-error {
    width: 100%;
    @include form-control($color-link);

    // To give style to the phonenumber input
    input {
        border: none;
        font-size: 1rem;
        opacity: 0.8;
        margin-left: 5px;
    }
}

.text-error {
    color: $color-link;
}

.form-select {
    @include form-select;
}

.form-group-year-renovation {
    display: block;
}

.form-group-private-pool {
    color: $color-white;

    @include md {
        color: $contact-reference;
    }
}
.form-group-private-pool-ads {
    color: $color-white;

    @include md {
        color: $ads-font;
    }
}

.private-pool {
    display: flex;
    flex-direction: column;

    @include lg {
        flex-direction: row;
    }

    @include md-min-max {
        flex-direction: column;
    }

    @include sm-min-max {
        flex-direction: row;
    }
}

.private-pool-question {
    text-shadow: 2.7px 0 5px $color-text-shadow;
    margin-bottom: 0.25rem;

    @include lg {
        margin-right: 1rem;
        text-shadow: none;
    }

    @include md-min-max {
        margin-right: 0;
        text-shadow: none;
    }

    @media (max-width: 768px) {
        padding-right: 10px;
    }
}

.private-pool-options {
    display: inline-flex;

    @include lg {
        flex-shrink: 0;
    }
}

/* The radio btn container */
.radio-btn-container {
    display: inline-block;
    @include radio-btn-container;

    /* Create a custom radio button */
    .checkmark {
        background: $color-white;
        @include checkmark;
    }
}

.btn {
    display: block;
    width: 100%;
    background: $color-button;
    border-color: $color-button;
    color: $color-white;
    font-family: $button-font-family;
    font-weight: 600;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.55rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-transform: none;

    &:hover {
        background: $color-button-hovor;
        border-color: $color-button-hovor;
        color: $color-white;
        text-decoration: none;
    }
}

// loading button
.loader {
    border: 2px solid $color-loader-border;
    border-top: 2px solid $color-button-hovor;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
    margin-left: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.btn-loading {
    display: block;
    width: 100%;
    background: $color-button-hovor;
    border-color: $color-button-hovor;
    color: $color-white;
    font-family: $button-font-family;
    font-weight: 600;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 1.55rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    text-transform: none;
}

.btn-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

// style for phone input
.form-control-phone {
    @include form-control-phone($color-button-border);
}
.form-control-phone-error {
    @include form-control-phone($color-link);
}

.react-tel-input {
    font-family: $button-font-family !important;
}

.fake-tooltip {
    position: relative;
    color: transparent;
    margin-top: 0.375rem;

    .arrow {
        position: absolute;
        display: block;
        width: 0.8rem;
        height: 0.4rem;
        top: -6px;
        left: 48%;

        &::before {
            bottom: 0;
            border-width: 0 0.4rem 0.4rem;
            position: absolute;
            content: "";
            border-bottom: 0.4rem $color-tooltip-border;
            border-style: solid;
        }
    }
}

.fake-tooltip-inner {
    background: $color-tooltip-border;
    color: $color-tooltip-inner;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
}

.fake-tooltip-text {
    text-align: left;
    display: flex;
}

.icon-info-gold {
    // background-image: url(/icons/icon-info-gold.svg);
    display: inline-block;
    // width: 22px;
    // height: 22px;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

.form-group-accept-terms {
    color: $color-white;

    @include md {
        color: $contact-reference;
    }
}

.accept-terms-text {
    margin-left: 2.635rem;

    a {
        color: $color-link;
        text-decoration: none;
        background-color: transparent;
        text-align: center;

        &:hover {
            text-decoration: underline;
        }
    }
}

.custom-switch {
    /* padding-left: 3.125rem; */
    position: relative;
    display: block;
    min-height: 1.5rem;
}

.switch {
    @include switch;
}

.link {
    color: $color-link;
    text-decoration: none;
    background-color: transparent;
    text-align: center;

    &:hover {
        text-decoration: underline;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1041;
}

/* make the above one inline for domain wise image import */

/* Listed Questions */

.faqSection {
    background: $section-color-alt;
    scroll-margin-top: 72px; //this value is equals to the height of navbar
}
.faqSection-ads {
    background: $ads-section-color-alt;
    color: $ads-font;
    scroll-margin-top: 72px; //this value is equals to the height of navbar
}

.faqH2 {
    font-size: 1.75rem;
    margin-bottom: 2rem;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;

    // 992px window width and more
    @include lg {
        font-size: 3.375rem;
    }
}

.faqRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.faqQuestionsCont {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    // 992px window width and more
    @include lg {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.faqCollapseContainer {
    position: relative;
    margin-top: -1px;
    border-top: 1px solid $border-primary;
}

.faqCollapseContainer-ads {
    position: relative;
    margin-top: -1px;
    border-top: 1px solid $ads-border;
}

.faqCollapseHeader {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.125rem;
    font-weight: 400;
    text-align: left;

    &:hover {
        cursor: pointer;
    }
}

.iconChevron {
    @include chevron(1rem);
}

.iconChevron-ads {
    @include chevron(1rem);
    border: 1px solid $ads-border;
}

.iconChevronCollapsed {
    @include chevron(1rem);
    transform: rotate(-180deg);
}

.iconChevronCollapsed-ads {
    @include chevron(1rem);
    transform: rotate(-180deg);
    border: 1px solid $ads-border;
}

.faqCollapsedContent {
    display: none;
    text-align: left;
    padding-left: 50px;

    a {
        text-decoration: none;
        background-color: transparent;
        color: $color-link;

        &:hover {
            text-decoration: underline;
        }
    }
}

.faqCollapsedContentCol {
    display: block;
    text-align: left;
    padding-left: 50px;

    a {
        text-decoration: none;
        background-color: transparent;
        color: $color-link;

        &:hover {
            text-decoration: underline;
        }
    }
}

.questionA {
    text-decoration: none;
    background-color: transparent;
    color: $color-link;

    &:hover {
        text-decoration: underline;
    }
}

/* Contact */
.contactUsSection {
    @include contactUsSection;
}
.contactUsSection-ads {
    background-color: $ads-section-color;
    color: $ads-font;
    @include contactUsSection;
}

// Used in faq also
.container {
    @include container;
}

.contactUsH2 {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;

    // 992px window width and more
    @include lg {
        font-size: 3.375rem;
    }
}

.contactP {
    color: $paragraph-primary;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 3rem;

    // 992px window width and more
    @include lg {
        font-size: 1.25rem;
    }
}
.contactP-ads {
    color: $ads-font;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 3rem;

    // 992px window width and more
    @include lg {
        font-size: 1.25rem;
    }
}

.contactOption {
    align-items: center;
    display: flex;
    flex-direction: column;

    // 768px window width and more
    @include md {
        justify-content: center;
        flex-direction: row;
    }
}

.singleIconContainer {
    display: flex;
    align-items: center;
    line-height: 1;
    width: 260px;
    margin-bottom: 2rem;

    // 768px window width and more
    @include md {
        width: auto;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
        margin-bottom: 0;
    }
}

.contactEmail {
    vertical-align: middle;
    width: 41px;
    aspect-ratio: auto 41 / 41;
    height: 41px;
}

.contactMessage {
    vertical-align: middle;
    position: relative;
    left: 5px;

    // 768px window width and more
    @include md {
        position: static;
        left: auto;
    }
}

.contactDiv {
    margin-left: 1.5rem;
    text-align: left;

    // 768px window width and more
    @include md {
        margin-left: 1rem;
    }
}

.contactA {
    @include contactA($contact-reference);
}

.contactA-ads {
    @include contactA($ads-font);
}

.contactAvail {
    color: $paragraph-primary;
    @include contactAvail;
}

.contactAvail-ads {
    color: $ads-font;
    @include contactAvail;
}

.margin-above {
    margin-top: 1.5rem;
}