@import "variables";
@import "mixins";

/* Brands */
.brand-usps {
    border-bottom: 1px solid $border-primary;
    display: block;
}
.brand-usps-ads {
    border-bottom: 1px solid $border-primary;
    display: block;
    color: $ads-font;
    background-color: $ads-section-color;
}

.brand-usps-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @include xl {
        align-items: center;
        padding-top: 2.25rem;
        padding-bottom: 2.25rem;
        max-width: 1140px;
    }

    @include lg-min-max {
        align-items: center;
        padding-top: 2.25rem;
        padding-bottom: 2.25rem;
        max-width: 960px;
    }

    @include md-min-max {
        max-width: 720px;
    }

    @include sm-min-max {
        max-width: 540px;
    }
}

.usp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    line-height: 1;
    flex-basis: 33.3333%;

    @include md {
        flex-direction: row;
        flex-basis: auto;
    }
}

.usp-last {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    line-height: 1;
    flex-basis: 33.3333%;

    @include lg {
        flex-direction: row;
        flex-basis: auto;
        display: flex;
    }

    @include md-min-max {
        flex-direction: row;
        flex-basis: auto;
    }
}

.image {
    vertical-align: middle;
    border-style: none;
    text-align: center;
    line-height: 1;

    @include sm-max {
        width: 30px;
        height: 30px;
    }
}

.usp-text {
    margin-top: 0.75rem;
    display: block;
    line-height: 1;

    @include lg {
        margin-left: 1rem;
        margin-top: 0;
        text-align: left;
        font-size: 1.125rem;
    }

    @include md-min-max {
        margin-top: 0;
        margin-left: 0.75rem;
        text-align: left;
        font-size: 1.125rem;
    }
}

.info-text {
    margin-bottom: 0.25rem;
    display: block;
    font-weight: 600;
    text-align: center;
    line-height: 1;

    @include md {
        text-align: left;
        font-size: 1.125rem;
    }
}
